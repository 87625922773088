import { Stack, Text, Title } from '@mantine/core';

export function HomePage() {
  return (
    <Stack>
      <Title order={1}>Weighted module calculator for UK degrees</Title>
      <Text>This site is under construction.</Text>
    </Stack>
  );
}

export default HomePage;
